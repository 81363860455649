<script lang="ts" setup>
import { Size } from '~/types/custom'
import type { Theme } from '~/types/theme'

const props = defineProps<{
  theme: Theme
  size?: Size
  active?: boolean
}>()

const active = toRef(props, 'active')

const classes = reactive({
  'w-6 h-3 md:w-8 md:h-4': props.size === Size.sm || !props.size,
  'w-8 h-6 md:w-10 md:h-6': props.size === Size.md,
  'w-8 h-8 md:w-12 md:h-12 rounded-md': props.size === Size.lg,
  active,
})
</script>

<template lang="pug">
.theme(
  :style="{ background:  `linear-gradient(to bottom left, ${props?.theme?.hexPrimary} 50%, ${props?.theme?.hexSecondary} 50%)` }"
  :class="classes"
)
</template>

<style lang="sass" scoped>
.theme
  @apply flex-shrink-0 relative cursor-pointer
  &.active
    @apply p-1
    &::after
      content: ''
      @apply absolute top-0 left-0 right-0 bottom-0 border border-white rounded-md
</style>
